import React from 'react';

/**
 * Validates login with phone or email.
 *
 * @param {string} login Input value.
 *
 * @returns {{errorText: string, isValid: boolean}}
 */
export default function useLoginValidation(login: string) {
    const [isValid, setIsValid] = React.useState(true);
    const [errorText, setErrorText] = React.useState('');

    // TODO: Add phone validation
    React.useEffect(() => {
        const isEmail = login.includes('@');
        const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(login);

        if (isEmail && !isEmailValid) {
            setIsValid(false);
            setErrorText('Введенный формат почты не действителен');
        } else {
            setIsValid(true);
            setErrorText('');
        }
    }, [login]);

    return {
        isValid,
        errorText
    };
}

export default {
    title: 'Дашборд',
    technicalWorks: {
        title: 'Технические работы',
        description: 'Описание',
        estimatedTime: 'Предполагаемое время завершения',
        selectDate: 'Выберите дату',
        enable: 'Включить техничские работы',
        on: 'Включены',
        off: 'Выключены'
    },
    confirmation: {
        title: 'Подтверждение',
        description: 'Вы уверены что хотите выключить технический режим? ',
        confirm: 'Подтверждаю'
    },
    currencyPairs: 'Валютные пары',
    deposit: 'Пополнение',
    withdrawal: 'Вывод',
    forToday: 'За сегодня',
    inWeek: 'За неделю',
    forMonth: 'За месяц',
    today: 'Сегодня',
    week: 'Неделя',
    month: 'Месяц',
    EUR: '€',
    RUB: '₽',
    USD: '$',
    RUBLES: 'RUBLES',
    RUBCASH: 'RUBCASH',
    USDCASH: 'USDCASH',
    USDT: 'USDT'
};

export default {
    title: 'Admins',
    add: 'Add',
    columns: {
        email: 'Email',
        name: 'Name',
        created: 'Creation date'
    },
    rows: {
        resetPass: 'Reset password',
        delete: 'Delete'
    },
    addSidebar: {
        title: 'Add an Administrator',
        name: 'Enter the name',
        email: 'Enter the email',
        buttonAdd: 'Add',
        buttonSave: 'Save',
        fields: 'Not all fields are filled in',
        success: 'Administrator is added',
        error: 'An Administrator with such email already exists'
    },
    deleteModal: {
        confirm: 'Confirmation',
        sure: 'Are you sure you want to',
        delete: 'delete',
        admin: 'the administrator',
        reset: 'reset the password for',
        noDelete: 'Do not delete',
        noReset: 'Do not reset',
        yesDelete: 'Delete',
        yesReset: 'Reset'
    },
    deleteSuccess: 'The user has been successfully deleted',
    resetSuccess: 'Password successfully reset'
};

export default {
    title: 'Транзакции',
    searchPlaceholder: 'Поиск',
    tabIn: 'Пополнение',
    tabOut: 'Вывод',
    tableHeader: {
        status: 'Статус',
        created: 'Создано',
        updated: 'Обновлено',
        wallet: 'Кошелёк',
        card: 'Карта / Кошелёк',
        paid: 'Оплачено',
        receive: 'Получено',
        transferId: 'Id заказа',
        orderId: 'Order id',
        transactionId: 'Transfer id'
    },
    status: {
        CREATED: 'Создан',
        MONEY_RECEIVED: 'Получено',
        PAID: 'Оплачено',
        SENDING: 'Обработка',
        SENT: 'Отправлено',
        SUCCESS: 'Оплачено',
        FAILED: 'Ошибка',
        CANCELLED: 'Отклонено',
        EXPIRED: 'Время вышло',
        REFUNDED: 'Возврат',
        FROZEN: 'Заморожена'
    },
    sortType: {
        CREATED_DESC: 'Созданные от новых к старым',
        CREATED_ASC: 'Созданные от старых к новым',
        UPDATED_DESC: 'Обновлённые от новых к старым',
        UPDATED_ASC: 'Обновлённые от старых к новым',
        SENT_DESC: 'По убыванию отправленной суммы',
        SENT_ASC: 'По возрастанию отправленной суммы',
        RECEIVED_DESC: 'По убыванию полученной суммы',
        RECEIVED_ASC: 'По возрастанию полученной суммы'
    },
    unfreezeSuccess: 'Транзакция разморожена',
    button: {
        unfreeze: 'Разморозить'
    },
    error: {
        unfreezeFailed: 'Не удалось разморозить',
        TransferNotFoundError: 'Транзакция не найдена'
    }
};

export default {
    title_login: 'Вход',
    title_signup: 'Регистрация',
    title_forgotPassword: 'Сбросить пароль',

    loginType: {
        email: 'По почте',
        phone: 'По номеру'
    },

    code_description_signup: 'Для подтверждения регистрации на <0>{{address}}</0> был выслан код подтверждения ',
    code_description_forgotPassword: 'Введите код подтверждения который был отправлен на <0>{{address}}</0>',

    forgotPassword_description_login:
        'Для того чтобы восстановить пароль, введите почту или телефон номер который вы указывали при регистрации',

    email: 'Почта',
    email_placeholder: 'Введите почту',
    phone: 'Номер телефона',
    password: 'Пароль',
    password_placeholder_login: 'Введите пароль',
    password_placeholder_signup: 'Придумайте пароль',
    password_placeholder_forgotPassword: 'Введите пароль',
    repeatPassword: 'Повторите пароль',
    repeatPassword_placeholder: 'Повторите пароль',

    btnForgotPassword: 'Забыли пароль?',

    btnSubmit_login: 'Войти в аккаунт',
    btnSubmit_signup: 'Зарегистрироваться',
    btnSubmit_forgotPassword: 'Продолжить',

    btnAlternative_login: 'Нет аккаунта? <0>Регистрация</0>',
    btnAlternative_signup: 'Уже есть аккаунт? <0>Войти</0>',

    alert: {
        signup: {
            emptyFields_phone: 'Для того чтобы продолжить, введите номер телефона и пароль',
            emptyFields_email: 'Для того чтобы продолжить, введите почту и пароль',
            passwordsDifferent: 'Пароли не совпадают',

            userExists_phone: 'Пользователь с таким номером телефона уже существует',
            userExists_email: 'Пользователь с такой почтой уже существует',

            invalidPassword: 'Пароль слишком слаб',

            invalidEmail: 'Неверная почта',
            invalidPhone: 'Неверный номер телефона'
        },
        login: {
            emptyFields_phone: 'Для того чтобы продолжить, введите номер телефона и пароль',
            emptyFields_email: 'Для того чтобы продолжить, введите почту и пароль',
            invalidCredentials_phone: 'Вы ввели неверный номер телефона или пароль',
            invalidCredentials_email: 'Вы ввели неверную почту или пароль'
        },

        forgotPassword: {
            emptyFields_phone: 'Для того чтобы продолжить, введите номер телефона',
            emptyFields_email: 'Для того чтобы продолжить, введите почту',
            emptyFields_password: 'Для того чтобы продолжить, введите пароль и повторите его',
            userNotFound: 'Пользователь не найден',
            noResendAttempts: 'Вы исчерпали все попытки. Попробуйте позже.',
            passwordsDifferent: 'Пароли не совпадают'
        },

        formCode: {
            hasNoAttempts: 'Вы исчерпали все попытки. Попробуйте позже'
        }
    }
};

import React, { useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { LOCAL_STORAGE_KEY_TOKEN_AUTH } from 'apollo/cache';

export type DeepPartial<T> = T extends Function ? T : T extends object ? { [P in keyof T]?: DeepPartial<T[P]> } : T;

export const getErrorData = (e: any) => ({
    message: e?.networkError?.result?.errors?.[0]?.extensions || e?.graphQLErrors?.[0]?.message,
    code: e?.graphQLErrors?.[0]?.code as undefined | number | null
});

export type UseFormField_DEPRECATED<T> = {
    value: T;
    error: string;
    change: (v: T, er?: string) => void;
    changeError: React.Dispatch<React.SetStateAction<string>>;
};

export function useFormField_DEPRECATED<T>(initialValue: T, withResetErrorOnChange = true): UseFormField_DEPRECATED<T> {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState('');

    return {
        value,
        error,
        change: (v: T, err = '') => {
            setValue(v);

            if (withResetErrorOnChange || err) setError(err);
        },
        changeError: setError
    };
}

/**
 * Helper to control need we close sidebar on click/tap overlay or sidebar directly
 * @param close will fire on direct click on overlay
 *
 * @example
 * const overlayClickHandler = useOverlayClickHandler(close)
 *
 * <div {...overlayClickHandler.overlayProps} className='overlay'>
 *     <div {...overlayClickHandler.componentProps}>
 *         content
 *     </div>
 * </div>
 */
export const useOverlayClickHandler = (close?: () => void) => {
    const shouldCloseRef = useRef<null | boolean>(null);

    const setShouldClose = (v: null | boolean) => {
        shouldCloseRef.current = v;
    };

    const handleOverlayOnClick = () => {
        if (shouldCloseRef.current === null) {
            setShouldClose(true);
        }

        if (shouldCloseRef.current) {
            close?.();
        }

        setShouldClose(null);
    };

    return {
        overlayProps: { onClick: handleOverlayOnClick },
        componentProps: {
            onMouseDown: () => setShouldClose(false),
            onMouseUp: () => setShouldClose(false),
            onClick: () => setShouldClose(false)
        }
    };
};

export const formatPhone = (phone = '') => phone.replace(/[^\d+]/g, '');

export const getToken = () => localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_AUTH);

export const AUTH_HEADER = 'Authorization';
export const constructAuthHeader = (token: string) => ({
    Authorization: `JWT ${token}`
});

// export const useHasStore = () => {
//     const { isAuth } = useStore((s) => s.Base);
//     const meQuery = useMeQuery({ skip: !isAuth });
//     const stores = meQuery?.data?.me?.stores;
//
//     return Boolean(stores?.length);
// };

export const useOverflowController = () => {
    const id = useRef(`${Math.random()}`).current;
    const blockScroll = (el: HTMLElement) => {
        window.overflowController = window.overflowController ?? {};
        window.overflowController[id] = el || true;
        document.body.style.overflow = 'hidden';
    };

    const unblockScroll = () => {
        window.overflowController = window.overflowController ?? {};
        delete window.overflowController[id];

        // eslint-disable-next-line @typescript-eslint/no-shadow
        Object.entries(window.overflowController).forEach(([id, element]) => {
            if (typeof element === 'object' && !element.parentElement) {
                delete window.overflowController[id];
            }
        });

        const hasOverflowElements = Object.values(window.overflowController).some(Boolean);

        if (!hasOverflowElements) {
            document.body.style.overflow = '';
        }
    };

    return {
        blockScroll,
        unblockScroll
    };
};

export const useFormatDate = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [t, { language }] = useTranslation();

    return (date: string) => DateTime.fromISO(`${date}Z`, { zone: 'local' }).setLocale(language);
};

export const useFormatDataNew = () => (date: string) => {
    const dateTime = new Date(date);
    const isDateNaN = Number.isNaN(dateTime.getDate());

    return !isDateNaN ? DateTime.fromISO(`${date}Z`).toFormat('dd.MM.yy, HH:mm') : '-';
};

export const useMinuteDifference = () => (firstDate: string, secondDate: string) => {
    const date1 = new Date(firstDate);
    const date2 = new Date(secondDate);
    const isDate1NaN = Number.isNaN(date1.getDate());
    const isDate2NaN = Number.isNaN(date2.getDate());
    const diffInMilliseconds = !isDate1NaN && !isDate2NaN ? Math.abs(date2.getTime() - date1.getTime()) : 0;

    return Math.ceil(diffInMilliseconds / (1000 * 60));
};

export const useGetDateTime = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [t, { language }] = useTranslation();

    return {
        getDateTimeFromISO: (isoDateString = '') => DateTime.fromISO(isoDateString).setLocale(language)
    };
};

export const useNumberFormat = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [t, { language }] = useTranslation();

    return {
        getCompact: (number = 0) => Intl.NumberFormat(language, { notation: 'compact' }).format(number)
    };
};

export const uuidv4 = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise
        const r = (Math.random() * 16) | 0;
        // eslint-disable-next-line no-bitwise
        const v = c === 'x' ? r : (r & 0x3) | 0x8;

        return v.toString(16);
    });

import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { LOCAL_STORAGE_KEY_TOKEN_AUTH } from 'apollo/cache';
import { useSigninMutation } from 'apollo/generated';
import removeSpaces from 'utils/removeSpaces/removeSpaces';
import { DRAG_ANIMATION_DURATION } from 'helpers/CONSTANTS_DEPRECATED';
import validator from 'helpers/validator';
import useLoginValidation from 'hooks/useLoginValidation/useLoginValidation';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import useFormField from 'hooks/useFormField/useFormField';
import useAuth from 'hooks/useAuth/useAuth';
import { useToast } from 'ui/Toast/Toast';
import Button from 'ui/Button/Button';
import TextField from 'ui/TextField/TextField';
import FormFieldPassword from '../FormFieldPassword/FormFieldPassword';
import styles from './AuthLogin.module.scss';

export default function AuthLogin() {
    const [t] = useTranslation();
    const onGlobalError = useGlobalError();
    const toast = useToast();
    const { login } = useAuth();

    const loginInput = useFormField('');
    const passwordInput = useFormField('');

    const [passwordError, setPasswordError] = React.useState(false);
    const formRef = React.useRef<HTMLFormElement>(null);

    const { isValid: loginIsValid, errorText: loginErrorText } = useLoginValidation(loginInput.value);

    const [signinMutation, { loading }] = useSigninMutation();

    /** Await finishing of drag animation */
    const onErrorForm = () => {
        const className = cn('DragAnimation');

        formRef.current?.classList.remove(className);
        formRef.current?.classList.add(className);
        setTimeout(() => formRef.current?.classList.remove(className), DRAG_ANIMATION_DURATION);
    };

    const handleLoginBlur = () => {
        if (!loginIsValid) {
            loginInput.handleChangeError(loginErrorText);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        loginInput.handleChangeError('');
        passwordInput.handleChangeError('');

        if (typeof window !== 'undefined' && window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_AUTH)) {
            window.localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN_AUTH);
        }

        if (!loginInput.value || !passwordInput.value) {
            onErrorForm();

            return toast.error('Для продолжения заполните все обязательные поля');
        }

        if (!loginIsValid) {
            return loginInput.handleChangeError(loginErrorText);
        }

        signinMutation({
            variables: {
                input: {
                    email: loginInput.value.includes('@') ? removeSpaces(loginInput.value) : '',
                    password: passwordInput.value
                }
            }
        })
            .then(({ data }) => {
                const signin = data?.signin;
                if (signin) {
                    switch (signin.__typename) {
                        case 'SigninSuccess': {
                            const { token } = signin;

                            login(token);
                            break;
                        }
                        case 'InvalidCredentialsError': {
                            onErrorForm();
                            loginInput.handleChangeError(' ');
                            passwordInput.handleChangeError('Вы ввели неверный логин или пароль');
                            break;
                        }
                        default: {
                            onGlobalError('Что то пошло не так', toast);
                        }
                    }
                } else {
                    onGlobalError('Неудачная авторизация', toast);
                }
            })
            .catch(async () => {
                setPasswordError(true);
                onErrorForm();
            });

        return undefined;
    };

    return (
        <div className={styles.Root}>
            <div className={styles.FormWrapper}>
                <h4>Вход</h4>
                <form
                    style={{ animationDuration: `${DRAG_ANIMATION_DURATION}ms` }}
                    ref={formRef}
                    onSubmit={handleSubmit}
                >
                    <TextField
                        classes={{ root: styles.FormLoginInput }}
                        label="Логин"
                        placeholder="Введите почту или телефон"
                        value={loginInput.value}
                        isError={!!loginInput.error}
                        helperText={loginInput.error}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            passwordInput.handleChangeError('');
                            loginInput?.handleChange(validator(e, e.target.value));
                        }}
                        onBlur={handleLoginBlur}
                        autoComplete="username"
                        required
                    />
                    <FormFieldPassword
                        classes={{ input: styles.FormPasswordInput }}
                        value={passwordInput.value}
                        isError={Boolean(passwordInput.error) || passwordError}
                        helperText={passwordInput.error}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            loginInput.handleChangeError('');
                            passwordInput?.handleChange(validator(e, e.target.value));
                        }}
                        onFocus={() => setPasswordError(false)}
                        autoComplete="current-password"
                        required
                    />
                    {passwordError && <span className={styles.PasswordError}>Вы ввели неверную почту или пароль</span>}
                    <Button
                        classes={{ root: styles.FormSubmitButton }}
                        loading={loading}
                        disabled={!loginInput.value || !passwordInput.value || passwordError}
                        size="large"
                        fullWidth
                        type="submit"
                    >
                        {t('pageSign.btnSubmit', { context: 'login' })}
                    </Button>
                </form>
            </div>
        </div>
    );
}

import React from 'react';
import { Navigate } from 'react-router-dom';
import { URLS_MAP } from 'routes/routes';
import useAuth from 'hooks/useAuth/useAuth';

export default function StartTour() {
    const { isAuth } = useAuth();

    if (isAuth) return <Navigate to={URLS_MAP.dashboard} />;

    return <Navigate to={URLS_MAP.login} />;
}

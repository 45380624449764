import transactions from './transactions';
import dashboard from './dashboard';
import pageSign from './pageSign';
import adminsList from './adminsList';
import modalLogout from './modalLogout';
import modalSession from './modalSession';
import noDataStub from './noDataStub';
import blackList from './blackList';

export default {
    adminsList,
    blackList,
    dashboard,
    modalLogout,
    modalSession,
    noDataStub,
    pageSign,
    transactions,

    pageDevelopment: {
        title: 'In development',
        description: 'This section is under development'
    },

    errors: {
        globalError: "There's been an error. Try again later",
        globalErrorTryMore: 'An unexpected error has occurred Try again.'
    },
    global: {
        copied: 'Copied',

        fee: 'Комиссия',

        btnReset: 'Сбросить',
        defaultStoreChanged: 'Выбран магазин “{{name}}”',

        units: {
            hours: 'час',
            hours_short: 'ч.',
            minutes: 'мин',
            minutes_short: 'м.'
        }
    },

    mode: {
        test: 'Тестовый режим',
        prod: 'Рабочий режим'
    },

    nav: {
        statistics: 'Статистика',
        orders: 'Заказы',
        transfers: 'Переводы средств',
        stores: 'Магазины',
        reports: 'Отчеты',
        logs: 'Пополнения и выводы',
        settings: 'Настройки',
        forDevelop: 'Для разработчиков'
    },

    passwordRules: {
        length: '8 символов',
        uppercase: '1 заглавный знак',
        number: '1 цифра',
        special: '1 спец. знак'
    },

    header: {
        profile: 'Профиль',

        sidebar: {
            stores: 'Магазины'
        }
    },

    currencies: {
        RUBCASH: 'Наличный рубль',
        USDCASH: 'Наличный доллар',
        UAHCASH: 'Наличная гривна'
    },

    orderStatus: {
        isTest: 'Тестовый заказ',
        isTest_short: 'Тест',

        CREATED: 'Pending payment',
        EXPIRED: 'Payment In Progress time expired',
        PAID: 'Paid',
        MONEY_RECEIVED: 'Paid Pending Confirmation',
        MONEY_RECEIVED_short: 'Waiting for confirmation',
        CANCELLED: 'Cancelled',
        PAYBACK: 'Refund'
    },

    orderParams: {
        store: 'Название магазина',
        number: 'Номер заказа',
        merchantOrderId: 'ID на сайте магазина',
        id: 'ID',
        fee: 'Комиссия',
        total: 'Итого',
        payback: 'Было возвращено покупателю',

        description: 'Описание',

        createdDate: 'Дата создания заказа',
        paymentDate: 'Время оплаты заказа',
        cancellationDate: 'Время отмены заказа',
        expirationDate: 'Время экспирации заказа',
        expirationTime: 'Время существования ссылки',
        expirationTime_units: 'мин.',
        redirectSuccess: 'Редирект при успешной оплате',
        redirectFailure: 'Редирект при неуспешной оплате',
        callback: 'Callback ссылка',

        token: 'Токен оплаты',
        received: 'Полученные средства',
        received_yet: 'Сумма заказа',
        payer: 'Аккаунт оплатившего',
        paymentLink: 'Ссылка на оплату',
        memo: 'MEMO',

        os: 'Операционная система',
        browser: 'Браузер',
        device: 'Устройство',
        ip: 'IP',
        country: 'Страна',
        language: 'Язык страницы'
    },

    formCode: {
        field: 'Код подтверждения',

        resendTimer: 'Код можно будет отправить снова через',
        resendBtn_description: 'Не получили код?',
        resendBtn: 'Отправить код подтверждения заново',

        resendCode: {
            alert: {
                success: 'Код подтверждения был отправлен заново. Также проверьте папку спам'
            }
        },

        alert: {
            invalidCode: 'Вы ввели неверный код подтверждения. ',
            codeExpired: 'Код подтверждения больше не активен'
        }
    },

    formCreateStore: {
        title_tour: 'Добро пожаловать',
        title_regular: 'Новый магазин',
        description: 'Для того чтобы начать принимать платежи, необходимо создать и настроить магазин',

        hint: 'Вы всегда сможете изменить данные\nпараметры в настройках магазина',

        alert: {
            nameAlreadyExists: 'Данное имя уже занято'
        },

        storeName: {
            title: 'Название магазина',
            field: 'Название магазина',
            field_placeholder: 'Придумайте название',

            alert: 'Для того чтобы продолжить, введите название для вашего магазина',

            btnSubmit: 'Продолжить'
        },
        currencies: {
            title: 'Криптоналичные',
            description: 'Выберите криптоналичные в которых будете принимать платежи',

            alert: 'Для того, чтобы продолжить, необходимо выбрать хотя бы один вид криптоналичичных',

            btnSubmit: 'Продолжить'
        },
        apiKey: {
            title: 'Ключ',
            description: 'Используйте данный ключ при подключении paycash к вашему сайту',
            important: 'Не передавайте ключ от вашего магазина посторонним лицам',

            field: 'Ключ API',

            btnHowTo: 'Как подключить к сайту?',

            alert: 'API ключ скопирован',

            btnSubmit: 'Готово'
        }
    },

    modalDisableTokenAlert: {
        title: 'Прекращение поддержки',

        disabledTokens:
            'PayCash for Store больше не поддерживает пополнение баланса и создание заказов в следующих токенах',

        recomendation: 'Рекомендуем вывести данные токены с баланса вашего магазина',

        recomendedTokens: 'Пополнение баланса и создание заказов работает в',

        agree: 'Ознакомился с информацией',
        btnSubmit: 'Принимаю'
    }
};

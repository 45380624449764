import React from 'react';
// import useIsTouchableDevice from 'hooks/useIsTouchableDevice/useIsTouchableDevice';
// import AuthMobile from 'components/AuthMobile/AuthMobile';
import Auth from 'components/Auth/Auth';
import AuthLogin from 'components/AuthLogin/AuthLogin';

export default function LoginPage() {
    // const isTouchableDevice = useIsTouchableDevice();

    return (
        <Auth>
            <AuthLogin />
        </Auth>
    );
}

import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { isAdminVar, isAuthVar } from 'apollo/cache';
import client from 'apollo';
import { login, logout } from 'helpers/Auth/Auth';
import { URLS_MAP } from 'routes/routes';

/**
 * Authentication hook.
 *
 * @returns {{
 *     isAuth: boolean,
 *     isAdmin: boolean,
 *     logout: (withShowSessionModal?: boolean) => void,
 *     login: (token: string, setIsAdmin?: boolean) => void,
 *     setAdmin: (setIsAdmin: boolean) => void
 * }}
 */
export default function useAuth() {
    const navigate = useNavigate();
    const isAuth = useReactiveVar(isAuthVar);
    const isAdmin = useReactiveVar(isAdminVar);

    return {
        isAuth,
        isAdmin,
        login: (token: string) => {
            login(token);
            navigate(URLS_MAP.root, { replace: true });
        },
        logout: (withShowSessionModal?: boolean) => {
            logout(withShowSessionModal);
            client.cache.reset().then(() => navigate(URLS_MAP.login, { replace: true }));
        }
    };
}

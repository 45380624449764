export default {
    title: 'Transactions',
    searchPlaceholder: 'search',
    tabIn: 'Deposit',
    tabOut: 'Withdrawal',
    tableHeader: {
        status: 'Status',
        created: 'Created',
        updated: 'Updated',
        wallet: 'Wallet',
        card: 'Card / Wallet',
        paid: 'Paid',
        receive: 'Receive',
        transferId: 'Transfer id',
        orderId: 'Order id',
        transactionId: 'Transaction id'
    },
    status: {
        CREATED: 'Created',
        MONEY_RECEIVED: 'Received',
        PAID: 'Paid',
        SENDING: 'Sending',
        SENT: 'Sent',
        SUCCESS: 'Paid',
        FAILED: 'Failed',
        CANCELLED: 'Cancelled',
        EXPIRED: 'Expired',
        REFUNDED: 'Refunded',
        FROZEN: 'Frozen'
    },
    sortType: {
        CREATED_DESC: 'Created from new to old',
        CREATED_ASC: 'Created from old to new',
        UPDATED_DESC: 'Updated from new to old',
        UPDATED_ASC: 'Updated from old to new',
        SENT_DESC: 'By decreasing amount sent',
        SENT_ASC: 'In ascending order of amount sent',
        RECEIVED_DESC: 'By decreasing amount received',
        RECEIVED_ASC: 'In ascending order of the amount received'
    },
    unfreezeSuccess: 'Transaction unfrozen',
    button: {
        unfreeze: 'Unfreeze'
    },
    error: {
        unfreezeFailed: 'Failed to unfreeze',
        TransferNotFoundError: 'Transaction not found'
    }
};

import React from 'react';
import { ExchangeStatusEnum, useExchangesQuery } from 'apollo/generated';
import useModal from 'hooks/useModal/useModal';
import { ExitIcon, ProfileFilledIcon } from 'ui/Icons/Icons';
import { useDialog } from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import { Navigation, NavigationMobile } from '../Navigation/Navigation';
import LogoutModal from '../AuthLogoutModal/AuthLogoutModal';
import Logo from '../Logo/Logo';
import { HeaderProps } from './Header.d';
import styles from './Header.module.scss';

export const LOCAL_STORAGE_ERROR_EXCHANGES_TIMESTAMP = 'failed_exchanges_timestamp';

export default function Header({ isLimitedView, LogoProps }: HeaderProps) {
    const profileModal = useModal(false);
    const logoutModal = useDialog();

    const [failedExchanges, setFailedExchanges] = React.useState(0);

    const { fetchMore } = useExchangesQuery({
        variables: {
            filters: {
                status: ExchangeStatusEnum.Failed
            },
            skip: 0,
            limit: 1
        },
        fetchPolicy: 'cache-and-network'
    });

    const getFailedTransactionsNumber = React.useCallback(async () => {
        const currentTimestamp = localStorage.getItem(LOCAL_STORAGE_ERROR_EXCHANGES_TIMESTAMP);

        const failedTransactionsVariables = {
            offset: 0,
            first: 0,
            filters: {
                createdAfter: currentTimestamp,
                status: ExchangeStatusEnum.Failed
            }
        };

        const { data } = await fetchMore({ variables: failedTransactionsVariables });

        const totalFailed = data?.exchanges?.pageInfo?.totalFiltered || 0;
        setFailedExchanges(totalFailed);
    }, [fetchMore]);

    React.useEffect(() => {
        const newTransfersTimer = setInterval(() => {
            getFailedTransactionsNumber()
                .then(() => {})
                .catch(() => clearInterval(newTransfersTimer));
        }, 10000);
        return () => clearInterval(newTransfersTimer);
    }, [getFailedTransactionsNumber]);

    return (
        <>
            <header className={styles.Root}>
                <div className={styles.Left}>
                    <Logo {...LogoProps} classes={{ root: styles.Logo }} />
                </div>
                {!isLimitedView && <Navigation failedExchangesNumber={failedExchanges} />}
                <div className={styles.Right}>
                    {isLimitedView && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            isDark
                            onClick={profileModal.open}
                            classes={{ root: styles.ProfileButton }}
                            iconStart={<ProfileFilledIcon className={styles.ProfileIcon} />}
                        >
                            Профиль
                        </Button>
                    )}
                    <Button
                        variant="outlined"
                        color="secondary"
                        isDark
                        onClick={() => logoutModal.setOpen(true)}
                        classes={{ root: styles.LogoutButton }}
                        iconStart={<ExitIcon className={styles.LogoutIcon} />}
                    />
                </div>
            </header>
            {!isLimitedView && <NavigationMobile />}
            <LogoutModal {...logoutModal} />
        </>
    );
}

export default {
    title: 'Администраторы',
    add: 'Добавить',
    columns: {
        email: 'Почта',
        name: 'Имя',
        created: 'Дата создания'
    },
    rows: {
        resetPass: 'Сбросить пароль',
        delete: 'Удалить'
    },
    addSidebar: {
        title: 'Добавить администратора',
        name: 'Введите имя',
        email: 'Введите почту',
        buttonAdd: 'Добавить',
        buttonSave: 'Сохранить',
        fields: 'Не все поля заполнены',
        success: 'Пользователь добавлен',
        error: 'Пользователь с такой почтой уже существует'
    },
    deleteModal: {
        confirm: 'Подтверждение',
        sure: 'Вы уверены, что хотите',
        delete: 'удалить',
        admin: 'администратора',
        reset: 'сбросить пароль для',
        noDelete: 'Нет, не удалять',
        noReset: 'Нет, не сбрасывать',
        yesDelete: 'Да, удалить',
        yesReset: 'Да, сбросить'
    },
    deleteSuccess: 'Пользователь успешно удалён',
    resetSuccess: 'Пароль успешно сброшен'
};

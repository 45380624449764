export default {
    title: 'Dashboard',
    technicalWorks: {
        title: 'Technical Works',
        description: 'Description',
        estimatedTime: 'Estimated time of completion',
        selectDate: 'Select date',
        enable: 'Enable technical work',
        on: 'Enabled',
        off: 'Disabled'
    },
    confirmation: {
        title: 'Confirmation',
        description: 'Are you sure you want to turn off tech mode? ',
        confirm: 'Confirm'
    },
    currencyPairs: 'Currency pairs',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    forToday: 'For today',
    inWeek: 'For the week',
    forMonth: 'For the month',
    today: 'Today',
    week: 'Week',
    month: 'Month',
    EUR: '€',
    RUB: '₽',
    USD: '$',
    RUBLES: 'RUBLES',
    RUBCASH: 'RUBCASH',
    USDCASH: 'USDCASH',
    USDT: 'USDT'
};

import React from 'react';
import { UseFormField } from './useFormField.d';

/**
 * Form field hook.
 *
 * @param {T} initialValue Field initialization value.
 * @param {boolean} withResetErrorOnChange If true the error is reset on each change. By default, `true`.
 *
 * @returns {{
 *     value: T;
 *     error: string;
 *     fieldRef: MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>;
 *     handleChange: (newValue: T, newError?: string) => void;
 *     handleChangeError: React.Dispatch<React.SetStateAction<string>>;
 * }}
 */
export default function useFormField<T>(initialValue: T, withResetErrorOnChange = true): UseFormField<T> {
    const [value, setValue] = React.useState(initialValue);
    const [error, setError] = React.useState('');
    const fieldRef = React.useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

    return {
        value,
        error,
        fieldRef,
        handleChange: React.useCallback(
            (newValue: T, newError = '') => {
                setValue(newValue);

                if (withResetErrorOnChange || newError) setError(newError);
            },
            [withResetErrorOnChange]
        ),
        handleChangeError: setError
    };
}

export interface UseTextField<T> {
    value: T;
    change: (newValue: T, newHelperText?: string) => void;
    error: boolean;
    errorChange: (isError: boolean, newHelperText?: string) => void;
    helperText: string;
    helperTextChange: (newHelperText: string) => void;
    ref: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | null>;
}

/**
 * Form field hook.
 *
 * @param {T} initialValue Field initialization value.
 * @param {string} initialHelperText Initial helper text.
 *
 * @returns {{
 *     ref,
 *     value,
 *     change: (newValue: T) => setValue(newValue),
 *     error,
 *     errorChange: (isError) => setError(isError),
 *     helperText,
 *     helperTextChange: (newHelperText) => setHelperText(newHelperText)
 * }}
 */
export function useTextField<T>(initialValue: T, initialHelperText = ''): UseTextField<T> {
    const [value, setValue] = React.useState(initialValue);
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState(initialHelperText);

    const ref = React.useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

    return {
        ref,
        value,
        change: (newValue, newHelperText) => {
            setError(false);
            setValue(newValue);

            if (newHelperText) {
                setHelperText(newHelperText);
            } else {
                setHelperText(initialHelperText);
            }
        },
        error,
        errorChange: (isError, newHelperText) => {
            setError(isError);

            if (newHelperText) {
                setHelperText(newHelperText);
            } else {
                setHelperText(initialHelperText);
            }
        },
        helperText,
        helperTextChange: (newHelperText) => setHelperText(newHelperText)
    };
}

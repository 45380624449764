import React from 'react';
import cn from 'classnames';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowDownFilled } from 'assets/icons/arrow-down-filled.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ArrowUpAndDown } from 'assets/icons/arrows-up-and-down.svg';
import { ReactComponent as BankCardFilled } from 'assets/icons/bank-card-filled.svg';
import { ReactComponent as Block } from 'assets/icons/block.svg';
import { ReactComponent as BlocksIo } from 'assets/icons/blocksio.svg';
import { ReactComponent as Book } from 'assets/icons/book.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as Cards } from 'assets/icons/cards.svg';
import { ReactComponent as CentFilledCircled } from 'assets/icons/cent-filled-circled.svg';
import { ReactComponent as ChainFilled } from 'assets/icons/chain-filled.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as CheckFilled } from 'assets/icons/check-filled.svg';
import { ReactComponent as CheckFilledCircled } from 'assets/icons/check-filled-circled.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron-up.svg';
import { ReactComponent as Clip } from 'assets/icons/clip.svg';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { ReactComponent as ClockFilled } from 'assets/icons/clock-filled.svg';
import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as CrossFilledCircled } from 'assets/icons/cross-filled-circled.svg';
import { ReactComponent as CrownFilled } from 'assets/icons/crown-filled.svg';
import { ReactComponent as DashboardFilled } from 'assets/icons/dashboard-filled.svg';
import { ReactComponent as Doc } from 'assets/icons/doc.svg';
import { ReactComponent as DocEmpty } from 'assets/icons/doc-empty.svg';
import { ReactComponent as DocFilled } from 'assets/icons/doc-filled.svg';
import { ReactComponent as Dots } from 'assets/icons/dots.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Exit } from 'assets/icons/exit.svg';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { ReactComponent as Garbage } from 'assets/icons/garbage.svg';
import { ReactComponent as Gear } from 'assets/icons/gear.svg';
import { ReactComponent as HexagonFilled } from 'assets/icons/hexagon-filled.svg';
import { ReactComponent as History } from 'assets/icons/history.svg';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as InfoCircled } from 'assets/icons/info-circled.svg';
import { ReactComponent as Key } from 'assets/icons/key.svg';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import { ReactComponent as Mail } from 'assets/icons/mail.svg';
import { ReactComponent as Menu } from 'assets/icons/menu.svg';
import { ReactComponent as Message } from 'assets/icons/message.svg';
import { ReactComponent as MessageCircled } from 'assets/icons/message-circled.svg';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { ReactComponent as MinusFilled } from 'assets/icons/minus-filled.svg';
import { ReactComponent as MoneyOrder } from 'assets/icons/money-order.svg';
import { ReactComponent as MoneyRefund } from 'assets/icons/money-refund.svg';
import { ReactComponent as MoneySend } from 'assets/icons/money-send.svg';
import { ReactComponent as Notification } from 'assets/icons/notification.svg';
import { ReactComponent as People } from 'assets/icons/people.svg';
import { ReactComponent as Phone } from 'assets/icons/phone.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as PlusFilled } from 'assets/icons/plus-filled.svg';
import { ReactComponent as Point } from 'assets/icons/point.svg';
import { ReactComponent as Print } from 'assets/icons/print.svg';
import { ReactComponent as ProfileFilled } from 'assets/icons/profile-filled.svg';
import { ReactComponent as Qr } from 'assets/icons/qr.svg';
import { ReactComponent as Question } from 'assets/icons/question.svg';
import { ReactComponent as QuestionCircled } from 'assets/icons/question-circled.svg';
import { ReactComponent as Rating } from 'assets/icons/rating.svg';
import { ReactComponent as Refresh } from 'assets/icons/refresh.svg';
import { ReactComponent as Reload } from 'assets/icons/reload.svg';
import { ReactComponent as RoundedSquare } from 'assets/icons/rounded-square.svg';
import { ReactComponent as SandClock } from 'assets/icons/sand-clock.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as SendFilled } from 'assets/icons/send.svg';
import { ReactComponent as ShareFilled } from 'assets/icons/share-filled.svg';
import { ReactComponent as Shop } from 'assets/icons/shop.svg';
import { ReactComponent as ShopFilled } from 'assets/icons/shop-filled.svg';
import { ReactComponent as ShowOff } from 'assets/icons/show-off.svg';
import { ReactComponent as ShowOn } from 'assets/icons/show-on.svg';
import { ReactComponent as Smartphone } from 'assets/icons/smartphone.svg';
import { ReactComponent as SocialTelegramFilled } from 'assets/icons/social-telegram-filled.svg';
import { ReactComponent as Sort } from 'assets/icons/sort.svg';
import { ReactComponent as Star } from 'assets/icons/star.svg';
import { ReactComponent as Table } from 'assets/icons/table.svg';
import { ReactComponent as TagClose } from 'assets/icons/tag-close.svg';
import { ReactComponent as TagCloseFilled } from 'assets/icons/tag-close-filled.svg';
import { ReactComponent as WarningFilled } from 'assets/icons/warning-filled.svg';
import { ReactComponent as WarningShield } from 'assets/icons/warning-shield.svg';
import styles from './Icons.module.scss';

type SvgIconProps = React.SVGProps<SVGSVGElement> & { className?: string };

const defaultProps = {
    'aria-hidden': true,
    focusable: false
};

export function ArrowLeftIcon({ className, ...props }: SvgIconProps) {
    return <ArrowLeft {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ArrowRightIcon({ className, ...props }: SvgIconProps) {
    return <ArrowRight {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ArrowUpIcon({ className, ...props }: SvgIconProps) {
    return <ArrowUp {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ArrowDownIcon({ className, ...props }: SvgIconProps) {
    return <ArrowDown {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ArrowUpAndDownIcon({ className, ...props }: SvgIconProps) {
    return <ArrowUpAndDown {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ArrowDownFilledIcon({ className, ...props }: SvgIconProps) {
    return <ArrowDownFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CalendarIcon({ className, ...props }: SvgIconProps) {
    return <Calendar {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function BankCardFilledIcon({ className, ...props }: SvgIconProps) {
    return <BankCardFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function BlockIcon({ className, ...props }: SvgIconProps) {
    return <Block {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function BlocksIoIcon({ className, ...props }: SvgIconProps) {
    return <BlocksIo {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CardsIcon({ className, ...props }: SvgIconProps) {
    return <Cards {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ChainFilledIcon({ className, ...props }: SvgIconProps) {
    return <ChainFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CheckIcon({ className, ...props }: SvgIconProps) {
    return <Check {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ChevronUpIcon({ className, ...props }: SvgIconProps) {
    return <ChevronUp {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CentFilledCircledIcon({ className, ...props }: SvgIconProps) {
    return <CentFilledCircled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ChevronDownIcon({ className, ...props }: SvgIconProps) {
    return <ChevronDown {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ChevronLeftIcon({ className, ...props }: SvgIconProps) {
    return <ChevronLeft {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ChevronRightIcon({ className, ...props }: SvgIconProps) {
    return <ChevronRight {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ClipIcon({ className, ...props }: SvgIconProps) {
    return <Clip {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ClockIcon({ className, ...props }: SvgIconProps) {
    return <Clock {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ClockFilledIcon({ className, ...props }: SvgIconProps) {
    return <ClockFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function RoundedSquareIcon({ className, ...props }: SvgIconProps) {
    return <RoundedSquare {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CheckFilledIcon({ className, ...props }: SvgIconProps) {
    return <CheckFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MinusFilledIcon({ className, ...props }: SvgIconProps) {
    return <MinusFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CheckFilledCircledIcon({ className, ...props }: SvgIconProps) {
    return <CheckFilledCircled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CopyIcon({ className, ...props }: SvgIconProps) {
    return <Copy {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CrossIcon({ className, ...props }: SvgIconProps) {
    return <Cross {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CrossFilledCircledIcon({ className, ...props }: SvgIconProps) {
    return <CrossFilledCircled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CrownFilledIcon({ className, ...props }: SvgIconProps) {
    return <CrownFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function DashboardFilledIcon({ className, ...props }: SvgIconProps) {
    return <DashboardFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function DocFilledIcon({ className, ...props }: SvgIconProps) {
    return <DocFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function DocEmptyIcon({ className, ...props }: SvgIconProps) {
    return <DocEmpty {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function DotsIcon({ className, ...props }: SvgIconProps) {
    return <Dots {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function DownloadIcon({ className, ...props }: SvgIconProps) {
    return <Download {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function EditIcon({ className, ...props }: SvgIconProps) {
    return <Edit {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function BookIcon({ className, ...props }: SvgIconProps) {
    return <Book {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function DocIcon({ className, ...props }: SvgIconProps) {
    return <Doc {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function FilterIcon({ className, ...props }: SvgIconProps) {
    return <Filter {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function GarbageIcon({ className, ...props }: SvgIconProps) {
    return <Garbage {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function HexagonFilledIcon({ className, ...props }: SvgIconProps) {
    return <HexagonFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function HomeIcon({ className, ...props }: SvgIconProps) {
    return <Home {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function HistoryIcon({ className, ...props }: SvgIconProps) {
    return <History {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function InfoCircledIcon({ className, ...props }: SvgIconProps) {
    return <InfoCircled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function KeyIcon({ className, ...props }: SvgIconProps) {
    return <Key {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function LockIcon({ className, ...props }: SvgIconProps) {
    return <Lock {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ExitIcon({ className, ...props }: SvgIconProps) {
    return <Exit {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MailIcon({ className, ...props }: SvgIconProps) {
    return <Mail {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MenuIcon({ className, ...props }: SvgIconProps) {
    return <Menu {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MinusIcon({ className, ...props }: SvgIconProps) {
    return <Minus {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MessageIcon({ className, ...props }: SvgIconProps) {
    return <Message {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MessageCircledIcon({ className, ...props }: SvgIconProps) {
    return <MessageCircled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MoneyOrderIcon({ className, ...props }: SvgIconProps) {
    return <MoneyOrder {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MoneyRefundIcon({ className, ...props }: SvgIconProps) {
    return <MoneyRefund {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MoneySendIcon({ className, ...props }: SvgIconProps) {
    return <MoneySend {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function NotificationIcon({ className, ...props }: SvgIconProps) {
    return <Notification {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function PeopleIcon({ className, ...props }: SvgIconProps) {
    return <People {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function PhoneIcon({ className, ...props }: SvgIconProps) {
    return <Phone {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function PlusIcon({ className, ...props }: SvgIconProps) {
    return <Plus {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function PlusFilledIcon({ className, ...props }: SvgIconProps) {
    return <PlusFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function PointIcon({ className, ...props }: SvgIconProps) {
    return <Point {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function PrintIcon({ className, ...props }: SvgIconProps) {
    return <Print {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ProfileFilledIcon({ className, ...props }: SvgIconProps) {
    return <ProfileFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function QrIcon({ className, ...props }: SvgIconProps) {
    return <Qr {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function QuestionIcon({ className, ...props }: SvgIconProps) {
    return <Question {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function QuestionCircledIcon({ className, ...props }: SvgIconProps) {
    return <QuestionCircled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function RatingIcon({ className, ...props }: SvgIconProps) {
    return <Rating {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function RefreshIcon({ className, ...props }: SvgIconProps) {
    return <Refresh {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ReloadIcon({ className, ...props }: SvgIconProps) {
    return <Reload {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function SandClockIcon({ className, ...props }: SvgIconProps) {
    return <SandClock {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function SearchIcon({ className, ...props }: SvgIconProps) {
    return <Search {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ShareFilledIcon({ className, ...props }: SvgIconProps) {
    return <ShareFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function SendFilledIcon({ className, ...props }: SvgIconProps) {
    return <SendFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function GearIcon({ className, ...props }: SvgIconProps) {
    return <Gear {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ShopIcon({ className, ...props }: SvgIconProps) {
    return <Shop {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ShopFilledIcon({ className, ...props }: SvgIconProps) {
    return <ShopFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ShowOffIcon({ className, ...props }: SvgIconProps) {
    return <ShowOff {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ShowOnIcon({ className, ...props }: SvgIconProps) {
    return <ShowOn {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function SmartphoneIcon({ className, ...props }: SvgIconProps) {
    return <Smartphone {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function SocialTelegramFilledIcon({ className, ...props }: SvgIconProps) {
    return <SocialTelegramFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function SortIcon({ className, ...props }: SvgIconProps) {
    return <Sort {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function StarIcon({ className, ...props }: SvgIconProps) {
    return <Star {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function TableIcon({ className, ...props }: SvgIconProps) {
    return <Table {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function TagCloseIcon({ className, ...props }: SvgIconProps) {
    return <TagClose {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function TagCloseFilledIcon({ className, ...props }: SvgIconProps) {
    return <TagCloseFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function WarningFilledIcon({ className, ...props }: SvgIconProps) {
    return <WarningFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function WarningShieldIcon({ className, ...props }: SvgIconProps) {
    return <WarningShield {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

import { useState } from 'react';
import { UseModalResult } from './useModal.d';

const useModal = <T extends any>(initialState = false): UseModalResult<T> => {
    const [isOpen, setIsOpen] = useState<boolean>(initialState);
    const [payload, setPayload] = useState<null | T>(null);

    const open = (p?: T) => {
        setIsOpen(true);
        setPayload(p ?? null);
    };

    const close = () => setIsOpen(false);

    return { isOpen, payload, open, close };
};

export default useModal;

export default {
    title: 'Чёрный список',
    add: 'Добавить',
    deleteSuccess: 'Пользователь успешно удалён из чёрного списка',
    addSuccess: 'Пользователь успешно добавлен в чёрный список',
    columns: {
        account: 'Аккаунт',
        created: 'Дата добавления'
    },
    rows: {
        delete: 'Удалить'
    },
    addModal: {
        title: 'Добавить аккаунт',
        label: 'Аккаунт',
        placeholder: 'Введите аккаунт',
        description: 'Укажите номер карты, EOS аккаунт или TRC-20 аккаунт',
        buttonTitle: 'Добавить'
    },
    deleteModal: {
        title: 'Подтверждение',
        confirmBtn: 'Да, удалить',
        cancelBtn: 'Нет, не удалять'
    }
};

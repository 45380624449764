export default {
    title: 'Black list',
    add: 'Add',
    columns: {
        account: 'Account',
        created: 'Addition date'
    },
    rows: {
        delete: 'Delete'
    },
    addModal: {
        title: 'Add account',
        label: 'Account',
        placeholder: 'Enter account',
        description: 'Provide card number, EOS account or TRC-20 account',
        buttonTitle: 'Add'
    },
    deleteModal: {
        title: 'Confirmation',
        confirmBtn: 'Yes, delete it.',
        cancelBtn: "No, don't delete it"
    },
    deleteSuccess: 'The user has been successfully deleted from black list',
    addSuccess: 'The user has been successfully added from black list'
};
